import { createContext, useState } from "react";
import i18n from 'i18next';

export let LangContext = createContext()

export default function LangContextProvider({children}){

    const [language, setLanguage] = useState(i18n.language);

     return <LangContext.Provider value={{language, setLanguage}}>
          {children}
     </LangContext.Provider>
}