import React, { useContext } from 'react'
import style from './SelectionPage.module.css'
import labsLogo from '../../assets/images/logo-mekyal-saudi.png'
import engLogo from '../../assets/images/Picture1-150x150.png'
import Bg1 from '../../assets/images/Picsart_24-11-21_12-37-38-645.jpg'
import Bg2 from '../../assets/images/pexels-sevenstormphotography-443399.jpg'
import Bg3 from '../../assets/images/skyline-1925943.jpg'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/autoplay"
import { EffectFade , Autoplay } from 'swiper/modules'
import { Link } from 'react-router-dom'

export default function SelectionPage() {

  const { t } = useTranslation()
  let {language}= useContext(LangContext)


  const labsSite = () =>{
    document.documentElement.classList.remove('company')
    sessionStorage.setItem('desiredPage' , 'labs')
    localStorage.setItem('desiredPage' , 'labs')
  }

  const companySite = () =>{
    document.documentElement.classList.add('company')
    sessionStorage.setItem('desiredPage' , 'company')
    localStorage.setItem('desiredPage' , 'company')
  }

  return <>
  <div className={`w-100 position-relative text-white vh-100 ${language === 'en' ? 'roboto-font' : 'cairo-font'}`}>
    <div className='vh-50 w-100 rounded-bottom-half overflow-hidden position-relative bg-white'>
    <div className='bg-black-40 position-absolute top-0 bottom-0 start-0 end-0 z-2'>
        <div className='container-lg px-lg-0 px-sm-4 px-3 m-auto h-100 position-relative'>
          <h1 className={`${style.font3} text-center pt-3 position-absolute start-0 w-100 cairo-font shadowed-text`}>
            <span className='roboto-font'>AlMekyal AlSaudi</span><br /> المكيال السعودي
          </h1>
          <div className='text-center flex-column h-100 d-flex justify-content-center align-items-center'>
            <p className={`fw-mediumF ${style.font5} pt-md-0 mt-4 lh-md position-relative line d-flex justify-content-center shadowed-text`}>{t("textSelection")}</p>
          </div>
        </div>
      </div>

        <Swiper className="w-100 h-100"
          modules={[EffectFade, Autoplay]}
          slidesPerView={1}
          // effect="fade"
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          direction="horizontal"
          speed={1000}
        >
          <SwiperSlide className='w-100 h-100 d-flex align-items-center justify-content-center'><img className='w-100 h-100 object-center object-cover' src={Bg1} alt="" /></SwiperSlide>
          <SwiperSlide className='w-100 h-100 d-flex align-items-center justify-content-center'><img className='w-100 h-100 object-center object-cover' src={Bg2} alt="" /></SwiperSlide>
          <SwiperSlide className='w-100 h-100 d-flex align-items-center justify-content-center'><img className='w-100 h-100 object-center object-cover' src={Bg3} alt="" /></SwiperSlide>
      </Swiper>
    </div>
      <div className="row justify-content-between fw-medium px-lg-4 px-sm-4 px-3 m-auto gx-3 gy-md-0 gy-5">
        <div className="col-md-6">
          <Link to={'/comingSoon'} onClick={labsSite} className={`scale ${style.bgMain} cursor-pointer d-flex flex-column w-100 text-white text-decoration-none ${style.h41} justify-content-center align-items-center rounded-2 shadow position-relative z-2`}>
            <p className={`text-center px-lg-2 px-md-1 px2 pt-3 ${style.font5} fw-semibold`}>{t("lab")}</p>
            <div className={`position-absolute bg-white rounded-circle d-flex align-items-center justify-content-center p-md-3 p-1 ${style.circleLogo} ${style.top}`}>
              <img className='w-100' src={labsLogo} alt=" labs logo" />
            </div>
          </Link>
        </div>
        <div className="col-md-6">
          <Link to={'/comingSoon'} onClick={companySite} className={`scale ${style.bgSec} cursor-pointer d-flex flex-column w-100 text-white text-decoration-none ${style.h41} justify-content-center align-items-center rounded-2 shadow position-relative z-2`}>
          <p className={`text-center px-lg-2 px-md-1 px-2 pt-3 ${style.font5} fw-semibold`}>{t("company")}</p>
            <div className={`position-absolute bg-white rounded-circle d-flex align-items-center justify-content-center p-md-4 p-2 ${style.circleLogo} ${style.top}`}>
              <img className='w-100' src={engLogo} alt="eng logo" />
            </div>
          </Link>
        </div>
    </div>
    <span className='position-absolute bottom-0 d-flex text-black align-items-center roboto-font vh-9 justify-content-center w-100 small text-center'>Copyright © ElMekyal AlSaudi 2024 Powered by&nbsp;
      <Link className='fw-bold text-black' to={'https://mas-itc.com'} target='_blank'> MAS-ITC</Link>
    </span>
  </div>
  </>
}
