import React, { useContext } from 'react'
import './assets/App.css'
import { RouterProvider } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { LangContext } from './components/context/LangContext.js'
import routes from './routes.js'


export default function App() {

  let {language}= useContext(LangContext)

  return <>
  <Helmet>
      <title>{language === 'en' ? 'AlMekyal AlSaudi' : 'المكيال السعودي'}</title>
  </Helmet>
  <RouterProvider router={routes}></RouterProvider>
  </>
}