import ComingSoon from "./components/comingSoon/ComingSoon"
import Layout from "./components/layout/Layout"
import {createBrowserRouter} from 'react-router-dom'
import SelectionPage from './components/SelectionPage/SelectionPage';

const routes = createBrowserRouter([
    { path: '', element: <Layout />, children: [
      { index: true, element: <SelectionPage /> },
      { path: 'comingSoon', element: <ComingSoon /> },
      { path: '*', element: <SelectionPage /> },
    ]}
  ])
export default routes
