import i18n from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(languageDetector)
.use(initReactI18next)
.init({
    debug: true,
    lng:"ar",
    resources: {
        ar: {
            translation: {
                greeting:"أهلاً بكم في المكيال السعودي",
                text:".نسعد بزيارتكم ونفيدكم بأنه جاري العمل على تطوير الموقع",
                contact:":للتواصل",
                infoName:"شركة المكيال السعودي للاستشارات الهندسية",
                labName:"مختبرات المكيال السعودي الهندسية",
                mail:" :البريد الإلكتروني",
                phone:" :الجوال",
                //////
                textSelection:"المكيال السعودي هي مجموعة تتكون من شركة المكيال السعودي للاستشارات الهندسية ومختبرات المكيال السعودي للتشييد والبناء مرخصة ومعتمدة لدى العديد من الجهات الحكومية وشبه الحكومية وتمارس أعمالها وفق ضوابط ومقاييس عالية ويعمل بها نخبة من الكفاءات الفنية في كافة الفروع، ويمكن الحصول على المزيد من المعلومات عبر الضغط على اسم المنشأة أسفل الصفحة.",
                lab:'مختبرات المكيال السعودي للتشييد والبناء',
                company:'شركة المكيال السعودي للاستشارات الهندسية',
                navHome:'الرئيسية',
                navAbout:'من نحن',
                navServices:'خدماتنا',
                navProjects:'مشاريعنا',
                navClients:'عملاؤنا',
                navSafety:'سياسيات الجودة والسلامة',
                navWorkshops:'ورش العمل ونقل المعرفة',
                navMediaCenter:'المركز الإعلامي',
                navContact:'تواصل معنا',
            },
        },
        en: {
            translation: {
                greeting:"Welcome at AlMekyal AlSaudi",
                text:"We are delighted to have you visit us and would like to inform you that work is underway to develop the website.",
                contact:"For Contact:",
                infoName:"AlMekyal AlSaudi Engineering Consulting Company",
                labName:"AlMekyal AlSaudi Engineering Laboratories",
                mail:"Email: ",
                phone:"Phone: ",
                //////
                textSelection: "AlMekyal AlSaudi is a group consisting of AlMekyal AlSaudi Engineering Consulting Company and AlMekyal AlSaudi Construction and Building Laboratories. It is licensed and accredited by various governmental and semi-governmental entities and operates according to high standards and regulations. It is staffed by a distinguished team of technical experts across all branches. For more information, you can click on the establishment's name at the bottom of the page.",
                lab: "AlMekyal AlSaudi Construction and Building Laboratories",
                company: "AlMekyal AlSaudi Engineering Consulting Company",
                navHome:'Home',
                navAbout:'About',
                navServices:'Services',
                navProjects:'Projects',
                navClients:'Clients',
                navSafety:'Quality and safety policies',
                navWorkshops:'Workshops and knowledge transfer',
                navMediaCenter:'Media Center',
                navContact:'Contact Us',
            },
        },
    }
})