import React, { useContext, useEffect } from 'react'
import style from './Layout.module.css';
import { Outlet, useLocation } from 'react-router-dom';
import { LangContext } from '../context/LangContext';
import i18n from 'i18next';
export default function Layout() {

  let {language, setLanguage}= useContext(LangContext);
  const location = useLocation()

  const toggleLanguage = () => {
      const newLanguage = language === 'en' ? 'ar' : 'en';
      i18n.changeLanguage(newLanguage);
      setLanguage(newLanguage);
    }
    
      
    useEffect(() => {
      if (location.pathname === "/selectYourSite") {
        sessionStorage.removeItem('desiredPage')
      }
    }, [location])
    

  return <>
        <span onClick={toggleLanguage} className={`position-fixed end-0 py-1 px-2 text-white lang-button ${language === 'en' ? 'cairo-font fw-bold' : 'roboto-font fw-semibold'} cursor-pointer`}>
          {language === 'en' ? 'عر' : 'En'}
        </span>
          <Outlet></Outlet>
  </>
}
