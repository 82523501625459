import React, { useContext } from 'react'
import style from './ComingSoon.module.css'
import labLogo from '../../assets/images/logo-mekyal-saudi.png'
import infoLogo from '../../assets/images/Picture1-150x150.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'

export default function ComingSoon() {
  const { t } = useTranslation();
  let {language}= useContext(LangContext)
  const site = sessionStorage.getItem('desiredPage')


  return <>
<div className={`text-white ${site==='company'? 'bg-main' : 'bg-main2'} ${language === 'en' ? 'roboto-font' : 'cairo-font'}`}>
  <div className="container min-vh-100 d-flex flex-column justify-content-between align-items-center">

                                                        {/* header */}
    <div className='text-center w-100'>
        <h1 className='display-5 fw-bold pt-5 pb-3'>{t("greeting")}</h1>
        <p className='fs-4 fw-medium'>{t("text")}</p>
    </div>

                                                        {/* contact */}
    <div className='w-100'>
        <p className={`fs-4 fw-bold py-5 ${language === 'en' ? '' : 'text-end'}`}>{t("contact")}</p>
        <div className={`row gy-5 gx-sm-5 g-0 mx-0 ${language === 'en' ? '' : 'flex-row-reverse'}`}>
          {site==='company'? <div className="col-lg-12">
            <div className='d-flex align-items-center justify-content-between flex-column fs-5'>
              <div className='d-flex align-items-center flex-column'>
                <div className={`${style.infoSize}`}>
                  <img className='w-100' src={infoLogo} alt="AlMekyal Logo" />
                </div>
                <h3 className={`pb-3 text-center ${language === 'en' ? 'fs-4' : ''}`}>{t("infoName")}</h3>
              </div>
              <div>
                <div className={`py-3 d-flex align-items-center justify-content-center ${language === 'en' ? '' : 'flex-row-reverse'}`}>
                  <p className={`mb-0 ${language === 'en' ? 'me-2' : 'ms-2'}`}>{t("mail")}</p>
                  <Link className='text-white text-center' to={'mailto:info@almekyal.com'}>info@almekyal.com</Link>
                </div>
                <div className={`py-3 d-flex align-items-center justify-content-center ${language === 'ar' ? 'flex-row-reverse' : ''}`}>
                  <p className={`mb-0 ${language === 'en' ? 'me-2' : 'ms-2'}`}>{t("phone")}</p>
                  <Link className='text-white text-center' to={'tel:+966555116840'}>+966555116840</Link>
                </div>
              </div>
            </div>
          </div>:<div className="col-lg-12">
            <div className='d-flex align-items-center h-100 justify-content-between flex-column fs-5'>
              <div className='d-flex align-items-center flex-column'>
                <div className={`${style.logoSize}`}>
                    <img className='w-100' src={labLogo} alt="AlMekyal Logo" />
                  </div>
                <h3 className={`pb-xl-4 pb-lg-2 pb-3 mb-xl-0 mb-lg-5 text-center ${language === 'en' ? 'fs-4' : ''}`}>{t("labName")}</h3>
              </div>
              <div>
                <div className={`py-3 d-flex align-items-center justify-content-center ${language === 'en' ? '' : 'flex-row-reverse'}`}>
                  <p className={`mb-0 ${language === 'en' ? 'me-2' : 'ms-2'}`}>{t("mail")}</p>
                  <Link className='text-white text-decoration-none' to={'mailto:lab@almekyal.com'}>lab@almekyal.com </Link>
                </div>
                <div className={`py-3 d-flex align-items-center justify-content-center ${language === 'ar' ? 'flex-row-reverse' : ''}`}>
                  <p className={`mb-0 ${language === 'en' ? 'me-2' : 'ms-2'}`}>{t("phone")}</p>
                  <Link className='text-white text-decoration-none' to={'tel:+9660501533348'}>+9660501533348 </Link>
                </div>
              </div>
            </div>
          </div>}  
        </div>
    </div>
                                                          {/* footer */}
    <span className='d-flex align-items-center roboto-font pb-4 pt-4 justify-content-center w-100 small text-center'>Copyright © ElMekyal AlSaudi 2024 Powered by&nbsp;
        <Link className='fw-bold text-white' to={'https://mas-itc.com'} target='_blank'> MAS-ITC</Link>
    </span>
  </div>
</div>
  </>
}